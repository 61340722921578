.skills-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
    margin: 0 75px 0 75px !important;
}

.skills-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 300px;
}

.skills-left h3, .skills-left h4 {
    cursor: default;
}

.skills-left h3 {
    font-family: 'Archivo', sans-serif;
    font-weight: 800;
    font-size: 50px;
    margin: 0px;
}

.skills-left h4 {
    font-family: 'Manrope', sans-serif;
    font-size: 20px;
}

.skills-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 750px;
}

i.colored {
    border: 1px solid transparent;
    padding: 12px;
    margin: 12px;
    font-size: 70px;
    transition: .5s;
}


i.devicon-visualstudio-plain.colored {
    color: #3380B4;
}

i.devicon-figma-plain.colored {
    color: #3380B4;
}

i.colored:hover {
    border: 1px solid #000;
    background-color: rgb(231, 231, 231);
    border-radius: .5rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

@media (max-width: 1400px) {
    .skills-wrapper {
        margin: 0 20px 0 20px !important;
    }
}

@media (max-width: 1200px) {
    .skills-wrapper {
        margin: 0 30px 0 30px !important;
        flex-direction: column !important;
        justify-content: start;
    }

    .skills-left {
        align-items: flex-start;
    }

    .skills-right {
        width: 100%;
    }

    i.colored {
        font-size: 60px;
    }
}

@media (max-width: 992px) {
    .skills-wrapper {
        margin: 0 20px 0 20px !important;
    }

    .skills-left {
        margin-bottom: 20px;
    }

    .skills-left h3 {
        font-size: 40px;
    }

    .skills-left h4 {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .skills-wrapper {
        margin: 0 10px 0 10px !important;
    }

    .skills-left {
        padding-left: 12px;
    }

    i.colored {
        font-size: 50px;
        margin: 0;
    }
}