footer {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: var(--fourth-color);
    display: flex;
    justify-content: space-between;
}

.foot-right, .foot-left {
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
}

.foot-left p {
    color: #000;
    margin-bottom: 0;
    padding: 8px;
    cursor: default;
}

.foot-right a {
    color: #000;
    text-decoration: none;
    padding: 8px;
}

.foot-right a:hover {
    color: #000;
}

.foot-icon {
    font-size: 16px;
}

@media (max-width: 992px) {
    footer {
        display: none !important;
    }
}