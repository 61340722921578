/* Archivo */
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Manrope */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

/* Roboto Mono */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

/* Source Serif Pro */
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

:root {
  --first-color: #1c658c;
  --second-color: #398ab9;
  --third-color: #d8d2cb;
  --fourth-color: #eeeeee;
  --five-color: #e1c16e;
  --six-color: #0d6efd;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--fourth-color);
}

.preload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--fourth-color);
  height: 100vh;
  width: 100%;
}

.preload h1 {
  font-family: 'Source Serif Pro', serif;
  margin: 0;
  cursor: default;
}